import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { SanityBasicImage } from 'graphql-types';
import { ImageType, ResponsiveImageType } from './types';
import { ImagePlaceholder } from '../image-placeholder/image-placeholder';
import { ShapeMask } from '../image-shape-mask/image-shape-mask';
import {
  ColorMask,
  ColorMaskProps
} from '../image-color-mask/image-color-mask';
import styles from './image.module.css';
import { useImageType } from '../../../hooks';

export interface ImageProps
  extends React.HTMLAttributes<HTMLImageElement>,
    SanityBasicImage {
  blob?: boolean;
  type?: ResponsiveImageType;
  loading?: 'lazy' | 'eager';
  decoding?: 'async' | 'sync' | 'auto';
  format?: 'webp' | 'jpg' | 'png' | 'pjpg';
  roundBorder?: boolean;
  sizes?: number[];
  mask?: Pick<ColorMaskProps, 'color' | 'removeOnHover'>;
  lazyLoadOptions?: {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    threshold?: number;
  };
}

const getQueryParamJoinChar = (url: string): string => {
  return url?.includes('?') ? '&' : '?';
};

const getImageSizes = (sizes?: number[]): string => {
  const unit = 'vw';
  if (sizes && sizes.length) {
    const breakpoints = ['758px'];
    const imageSizes = sizes.reduce((acc, size, index) => {
      const isFirst = index === 0;
      if (isFirst) return `${size}${unit}`;
      return `(min-width: ${breakpoints[index - 1]}) ${size}${unit}, ${acc}`;
    }, '');
    return imageSizes;
  }
  return `100${unit}`;
};

export const getSrc = (
  url: string,
  format: string,
  sizes?: number[]
): string => {
  let src = `${url}${getQueryParamJoinChar(url)}${format && `fm=${format}`}`;

  if (sizes && sizes.length === 1) {
    src = `${src}&w=${sizes[0]}`;
  }

  return src;
};

const getSrcSet = (
  src: string,
  format?: string,
  sizes: number[] = [150, 300, 500, 700, 900, 1200]
): string => {
  const srcSet = sizes
    .map(
      size =>
        `${src}${getQueryParamJoinChar(src)}w=${size}&fm=${format} ${size}w`
    )
    .join(', ');
  return srcSet;
};

const aspectRatio: Record<ImageType, string> = {
  [ImageType.RECTANGLE]: '1/1',
  [ImageType.RECTANGLE_XL]: '4/5',
  [ImageType.SQUARE]: '5/4',
  [ImageType.ROUND]: '1/1',
  [ImageType.CIRCLE]: '1/1',
  [ImageType.LARGE]: '16/9',
  [ImageType.SVG]: 'auto',
  [ImageType.BLOB_BOTTOM]: '7/3'
};

export const Image: FC<ImageProps> = ({
  type = ImageType.RECTANGLE,
  asset,
  alt,
  blob,
  roundBorder = true,
  className = '',
  sizes,
  mask,
  loading = 'lazy',
  decoding = 'auto',
  format = 'webp',
  lazyLoadOptions = {
    marginBottom: '300px',
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '0px',
    threshold: 0
  },
  ...props
}) => {
  const isFixedSize = sizes?.length === 1;
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [uniqueId] = useState(
    Math.random()
      .toString()
      .substring(2, 10)
  );
  const [imageType, setImageType] = useState(useImageType(type));
  const sufix = roundBorder ? 'rounded' : 'no-rounded';
  const [SVGId, setSVGId] = useState(
    `svg-blob-${imageType}-${sufix}-${uniqueId}`
  );
  const source = getSrc(asset?.url, format, sizes);
  const sourceSet = getSrcSet(source, format);
  const imageSizes = getImageSizes(sizes);
  const [isBlobBottom] = useState(imageType === 'blobBottom');
  const [isLoading, setIsLoading] = useState(loading === 'lazy');
  const [isMobile, setIsMobile] = useState(false);
  const blobBottomMask = isBlobBottom && isMobile;

  const onLoad = () => {
    setIsLoading(false);
  };

  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    blob
      ? setSVGId(`svg-no-blob-${imageType}-${sufix}-${uniqueId}`)
      : setSVGId(`svg-blob-${imageType}-${sufix}-${uniqueId}`);

    // Detect if the screen is mobile size
    const handleResize = () => {
      if (window.matchMedia('(max-width: 758px)').matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);

    if (isMobile && isBlobBottom) {
      setImageType(ImageType.BLOB_BOTTOM);
    } else {
      if (isBlobBottom) {
        setImageType(ImageType.RECTANGLE);
        setSVGId(`svg-no-blob-blobBottom-rounded-${uniqueId}`);
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [SVGId, blob, imageType, isBlobBottom, isMobile, sufix, type, uniqueId]);

  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return;
          else {
            setIsIntersecting(true);
            imgObserver.disconnect();
          }
        });
      },
      {
        rootMargin: `${lazyLoadOptions.marginTop} ${lazyLoadOptions.marginRight} ${lazyLoadOptions.marginBottom} ${lazyLoadOptions.marginLeft}`,
        threshold: lazyLoadOptions.threshold
      }
    );

    imgObserver.observe(imageRef.current);
  }, [isIntersecting, setIsIntersecting, imageRef, lazyLoadOptions]);

  // Image Render
  return (
    <div
      className={clsx('relative w-full', className, {
        [styles.rounded]: roundBorder && !blobBottomMask,
        'image-mask-container': mask,
        'image-mask-container-remove-on-hover': mask?.removeOnHover
      })}
      style={{
        aspectRatio: aspectRatio[imageType]
      }}
    >
      {isLoading ? <ImagePlaceholder svgRefId={SVGId} /> : null}
      <picture>
        <img
          {...props}
          alt={alt}
          src={isIntersecting ? source : undefined}
          data-src={source}
          srcSet={isFixedSize ? undefined : sourceSet}
          sizes={isFixedSize ? undefined : imageSizes}
          onLoad={onLoad}
          style={{
            clipPath: `url(#${SVGId})`,
            aspectRatio: aspectRatio[imageType]
          }}
          className={styles.image}
          loading={loading}
          decoding={decoding}
          ref={imageRef}
        />
      </picture>
      {!isLoading && mask && <ColorMask {...mask} svgRefId={SVGId} />}
      <ShapeMask
        svgRefId={SVGId}
        blob={isBlobBottom && !isMobile ? false : blob}
        type={imageType}
      />
    </div>
  );
};
