import React, { FC, useState, useCallback, useRef } from 'react';
import { Button } from '../../atoms/button/button';
import './provider-grid.scss';
import {
  ProviderCard,
  ProviderCardProps
} from '../../molecules/provider-card/provider-card';
import { DoctorListFilters, Text } from '../..';
import { DoctorListFiltersProps } from '../../types';
import { useMask } from '../../../hooks/use-mask';
import { FadeIn } from '../../atoms/fade-in/fade-in';

export type providerGridProps = {
  providers: ProviderCardProps[];
  headline?: string;
  defaultLimit?: number;
  filter?: DoctorListFiltersProps;
  ctaTrackingLabelMore?: string;
  ctaTrackingLabelLess?: string;
};

export const ProviderGrid: FC<providerGridProps> = ({
  providers,
  headline,
  defaultLimit = 8,
  filter,
  ctaTrackingLabelMore,
  ctaTrackingLabelLess
}) => {
  const { setMask } = useMask();
  const filterRef = useRef<HTMLDivElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [rowLimit, setRowLimit] = useState<number | undefined>(defaultLimit);
  const providersLength = providers.length;
  const viewLessHandler = useCallback(() => setRowLimit(defaultLimit), [
    setRowLimit,
    defaultLimit
  ]);

  const viewMoreHandler = useCallback(() => setRowLimit(undefined), [
    setRowLimit
  ]);
  const onToggleFilter = useCallback(
    (newState: boolean) => {
      if (newState && filterRef.current) {
        filterRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
      setMask(newState);
      setIsFilterOpen(newState);
    },
    [setMask]
  );

  return (
    <div className="provider-grid-wrapper" ref={filterRef}>
      <FadeIn
        className={`provider-grid-header ${
          isFilterOpen ? 'provider-grid-header-with-mask' : ''
        }`}
      >
        {headline && (
          <Text variant="h1a" className="text-center">
            {headline}
          </Text>
        )}
        {filter && (
          <DoctorListFilters {...filter} onToggleFilter={onToggleFilter} />
        )}
      </FadeIn>
      <div className="provider-grid-container">
        {providers.slice(0, rowLimit).map((provider, i) => (
          <ProviderCard {...provider} key={`${provider.id}-${i}`} />
        ))}
      </div>
      {defaultLimit && providersLength > defaultLimit ? (
        <div className="provider-grid-button-container mt-lg">
          {rowLimit ? (
            <Button
              variant="outlined"
              onClick={viewMoreHandler}
              trackingLabel={ctaTrackingLabelMore}
            >
              Show More
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={viewLessHandler}
              trackingLabel={ctaTrackingLabelLess}
            >
              Show Less
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};
