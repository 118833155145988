import React from 'react';
import { StructuredList, Text, TextLockup } from '../..';

export interface NumberedListProps {
  items: string[];
}

export const NumberedList = ({ items }: NumberedListProps) => {
  return (
    <div className="numbered-list">
      <StructuredList>
        {items.map((item, index) => {
          const num = (index + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          });

          const id = `${index}-${item.split(' ').join('')}`;
          return (
            <div className="flex flex-row w-full pb-xxs pt-xxs" key={id}>
              <Text variant="p2a" color="sage" className="mr-md">
                {num}
              </Text>
              <TextLockup alignment="left" fullWidth={true}>
                <Text variant="p2a">{item}</Text>
              </TextLockup>
            </div>
          );
        })}
      </StructuredList>
    </div>
  );
};
