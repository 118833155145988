import React, { FC, useState } from 'react';
import clsx from 'clsx';
import styles from './standard-carousel.module.css';
import { Text, TextLockup } from '../..';
import { TTextVariants } from '../../types';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { AdvisoryBoardCard } from '../../molecules/advisory-board-card/advisory-board-card';
import { StandardCarouselSlide } from '../../molecules/standard-carousel-slide/standard-carousel-slide';
import { SanityStandardCarousel } from 'graphql-types';

export interface StandardCarouselProps extends SanityStandardCarousel {}

export const StandardCarousel: FC<StandardCarouselProps> = ({
  eyebrow,
  heading,
  type = 'conditions',
  slides,
  members
}) => {
  const [isMoving, setIsMoving] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { min: 758, max: 100000 },
      items: 3
    },
    device: {
      breakpoint: { max: 758, min: 0 },
      items: 1
    }
  };

  const handleCarouselAfterChange = () => {
    window.heap.track('Carousel Swipe', {
      carouselName: 'Standard Carousel'
    });
    setIsMoving(false);
  };

  return (
    <div className={clsx(styles.standardCarouselContainer, 'text-center')}>
      <TextLockup alignment="center" className="mb-lg">
        {eyebrow && (
          <Text
            className="text-lockup-eyebrow"
            color="dark-green"
            variant={(eyebrow?.variantStyle as TTextVariants) || 'p3b'}
          >
            {eyebrow.text}
          </Text>
        )}
        {heading && (
          <Text
            className="text-lockup-headline"
            color="dark-green"
            variant={(heading?.variantStyle as TTextVariants) || 'h2a'}
          >
            {heading.text}
          </Text>
        )}
      </TextLockup>
      <ParsleyCarousel
        responsive={responsive}
        centerMode={true}
        containerClass={styles.carouselContainer}
        itemClass={styles.carouselSlide}
        infinite={true}
        leftArrowClass={styles.leftButton}
        rightArrowClass={styles.rightButton}
        beforeChange={() => setIsMoving(true)}
        afterChange={() => handleCarouselAfterChange()}
      >
        {type === 'conditions' &&
          slides.map((s, i) => (
            <StandardCarouselSlide
              key={`${s._key}-${i}`}
              image={s?.image}
              title={s.title}
              description={s.description}
              cta={s.cta}
              isCarouselMoving={isMoving}
            />
          ))}
        {type === 'advisoryBoard' &&
          members.map((member, i) => (
            <AdvisoryBoardCard
              {...member}
              key={`${member._key}-${i}`}
              image={member?.image}
            />
          ))}
      </ParsleyCarousel>
    </div>
  );
};
