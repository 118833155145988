import React, { FC } from 'react';
import clsx from 'clsx';
import { Row, Column } from '../../atoms/grid';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';
import { GridOrientation, TTextColor, ImageProps } from '../../types';
import styles from './half-and-half-numbered-list.module.css';
import {
  NumberedList,
  NumberedListProps
} from '../../molecules/numbered-list/numbered-list';

export type ContentPosition = 'center' | 'top';
export interface HalfAndHalfProps extends NumberedListProps {
  imgBgColor?: TTextColor;
  bgColor?: TTextColor;
  image?: ImageProps;
  imagePosition?: 'left' | 'right';
  fullImage?: boolean;
  mobileHidden?: boolean;
  reversed?: GridOrientation;
  children?: any;
}

export const HalfAndHalfNumberedList: FC<HalfAndHalfProps> = ({
  children,
  image,
  imagePosition,
  fullImage,
  imgBgColor = '',
  bgColor = '',
  mobileHidden = false,
  reversed,
  items
}) => {
  const isReversed =
    reversed !== undefined ? reversed : imagePosition === 'right';

  const cols = [22, 11];

  return (
    <Row
      cols={cols}
      gridLength={24}
      reversed={isReversed}
      className={clsx(styles.container, styles[imagePosition], styles.round)}
    >
      <Column
        className={clsx(mobileHidden && styles.mobileHidden, {
          'pt-xl pb-xl': imgBgColor,
          [`bg-${imgBgColor || bgColor}`]: imgBgColor || bgColor
        })}
      >
        <div
          className={clsx(
            {
              'w-full h-full': fullImage,
              'self-align w-pct-17/20': !fullImage
            },
            styles.imageWrapper
          )}
        >
          <Image {...image} type={image?.type ?? ImageType.SQUARE} />
        </div>
      </Column>
      <Column
        className={clsx({
          [`bg-${bgColor}`]: bgColor,
          'pt-sm pb-sm': imgBgColor
        })}
      >
        <div className={clsx('pt-sm pb-sm', styles.content)}>
          <div className="mb-lg">{children}</div>
          <NumberedList items={items} />
        </div>
      </Column>
    </Row>
  );
};
