import React from 'react';
import styles from './text.module.css';
import clsx from 'clsx';
import { TTextColor } from '../../types';
import { useTextWeight } from '../../../hooks/use-text-weight';
import '../../../components/pure-css/typography.css';

const textVariants = {
  h1a: 'textH1a',
  h1b: 'textH1b',
  h2a: 'textH2a',
  h2b: 'textH2b',
  h3a: 'textH3a',
  h3b: 'textH3b',
  h3c: 'textH3c',
  h3d: 'textH3d',
  h4a: 'textH4a',
  h4b: 'textH4b',
  h5a: 'textH5a',
  h5b: 'textH5b',
  h6a: 'textH6a',
  h6b: 'textH6b',
  h7a: 'textH7a',
  h7b: 'textH7b',
  h7c: 'textH7c',
  h8a: 'textH8a',
  h8b: 'textH8b',
  p1a: 'textP1a',
  p1b: 'textP1b',
  p2a: 'textP2a',
  p2b: 'textP2b',
  p3a: 'textP3a',
  p3b: 'textP3b',
  p1c: 'textP1c',
  pNoStyles: 'pNoStyles',
  u1a: 'textUtility1a',
  u1b: 'textUtility1b',
  u1c: 'textUtility1c',
  u3a: 'textUtility3a',
  u3c: 'textUtility3c',
  u1bLink: 'textUtility1bLink',
  u2: 'textUtility2',
  u3: 'textUtility3',
  u3b: 'textUtility3b',
  statLarge: 'textStatLarge',
  statSmall: 'textStatSmall',
  nav: 'textNav',
  widget: 'widgetText'
};
export type TTextVariants = keyof typeof textVariants;

export type TextWeight = 'light' | 'normal' | 'bold';

export interface TextProps
  extends React.HTMLProps<HTMLParagraphElement | HTMLHeadingElement> {
  variant: TTextVariants;
  color?: TTextColor;
  htmlTag?: React.ElementType;
  weight?: TextWeight;
}

export const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    { variant = 'p1a', color, htmlTag, children, className, weight, ...rest },
    ref
  ) => {
    const firstChars = variant.substring(0, 2);
    const { isBold, isLight } = useTextWeight(weight);

    let Component;
    if (htmlTag) {
      Component = htmlTag;
    } else if (firstChars.charAt(0) === 'h') {
      if (Number(firstChars.charAt(1)) >= 7) Component = 'h6';
      else Component = firstChars;
    } else if (firstChars.charAt(0) === 'p') {
      Component = 'p';
    } else {
      Component = 'span';
    }
    const variantName = textVariants[variant];
    return (
      <Component
        ref={ref}
        className={clsx(styles[variantName], className, {
          [styles.bold]: isBold,
          [styles.light]: isLight,
          [`text-${color}`]: color
        })}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);
