import React, { FC } from 'react';

import { QuoteMeta, Quote } from '../../';
import { QuoteProps, QuoteMetaProps } from '../../types';
import { Row, Column } from '../../atoms/grid';
import styles from './single-quote.module.css';
import useResponsiveProps from '../../../hooks/use-responsive-props';

export interface SingleQuoteProps extends QuoteProps {
  meta: QuoteMetaProps;
}

export const SingleQuote: FC<SingleQuoteProps> = ({ meta, ...quote }) => {
  const { getPropV2 } = useResponsiveProps();
  return (
    <Row
      cols={25}
      gap={0}
      className={`mr-md ml-md ${styles.justifyContent} ${getPropV2(
        ['xl', 'xxl'],
        'py'
      )}`}
    >
      <Column offset={2} cols={21}>
        <Quote {...quote} />
      </Column>
      <Column cols={25} className={styles.quoteMeta}>
        <QuoteMeta {...meta} active />
      </Column>
    </Row>
  );
};
